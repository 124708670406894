import React, { useState } from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";
import SectionContainer from "../../components/sectionContainer";
import MarqueeText from "../../components/marqueeText";
import useDeviceType from "../../useDeviceType";

const partnersItems = [
  {
    url: "/2023/images/partners/tinkoff.svg",
    text: "Генеральный партнер",
  },
  {
    url: "/2023/images/partners/dbrain.svg",
    text: "Партнер",
    imageClassName: styles.dbrainImage,
  },
  {
    url: "/2023/images/partners/b-o.jpg",
    text: "Инфопартнер",
    className: `${styles.row2} ${styles.containerImgBig}`,
  },
  {
    url: "/2023/images/partners/finversia.svg",
    text: "Инфопартнер",
    className: styles.row2,
  },
  {
    url: "/2023/images/partners/ФП.svg",
    text: "Инфопартнер",
    className: `${styles.row2} ${styles.containerImgBig}`,
  },
  {
    url: "/2023/images/partners/nbj.svg",
    text: "Инфопартнер",
    className: `${styles.row3} ${styles.containerImgBig}`,
  },
];

const Partners = () => {
  const [showAllPartners, setShowAllPartners] = useState(false);
  const device = useDeviceType();
  const partnersPerPage = device === "desktop" ? 9 : 6;
  const togglePartners = () => {
    setShowAllPartners((prevShowAllPartners) => !prevShowAllPartners);
  };

  return (
    <section className={styles.partners}>
      <Container className={styles.container}>
        <SectionContainer
          headlineFn={() => "Партнеры"}
          style={{
            containerSection: styles.containerSection,
            headline: styles.sectionHeadline,
          }}
        >
          <div
            className={`${styles.content}${
              showAllPartners ? ` ${styles.showHidePartners}` : ""
            }`}
          >
            {partnersItems.map(({ url, text, className, imageClassName }, idx) => {
              return (
                <div
                  className={`${
                    idx >= partnersPerPage ? styles.hidePartners : ""
                  }${` ${styles.item} ${className ? className : ""}`}`}
                  key={idx}
                >
                  <div
                    style={{ backgroundImage: `url(${url})` }}
                    className={`${styles.img} ${imageClassName ? imageClassName : ""}`}
                  />
                  <span className={styles.text}>{text}</span>
                </div>
              );
            })}
          </div>
          {partnersItems.length > partnersPerPage && !showAllPartners && (
            <MarqueeText
              onClick={togglePartners}
              text={`Показать ещё ${Math.abs(
                partnersItems.length - partnersPerPage
              )}`}
            />
          )}
        </SectionContainer>
      </Container>
    </section>
  );
};

export default Partners;
