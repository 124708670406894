import React from "react";
import styles from "./styles.module.styl";

const MainBlockBg = () => {
  return (
    <div className={styles.mainBlockBg}>
      <div className={`${styles.image} ${styles.mainImage}`}></div>
      <div className={`${styles.image} ${styles.mainOverlayImage}`}></div>
    </div>
  );
};

export default MainBlockBg;
