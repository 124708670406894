import React from "react";
import Container from "../../components/container";
import styles from "./styles.module.styl";
import SectionContainer from "../../components/sectionContainer";

const pairAwards = (data) => {
  return data.reduce((acc, award, index) => {
    if (index % 2 === 0) acc.push([award]);
    else acc[acc.length - 1].push(award);
    return acc;
  }, []);
};

const awardsData = [
  {
    number: "01",
    text: "Работа экспертного совета премии: формирование шорт-листа",
    date: "— декабрь 2023 - январь 2024",
  },
  {
    number: "02",
    text: "Определение списка победителей",
    date: "— февраль 2024",
  },
  {
    number: "03",
    text: "Проведение церемонии вручения премии Банки.ру",
    date: "— 21 марта 2024",
  },
];

const AwardDate = () => {
  return (
    <section className={styles.awardDate}>
      <Container className={styles.container}>
        <SectionContainer headlineFn={() => <>
          Этапы <br className={styles.hideMobile} /> проведения <br className={styles.hideMobile} /> премии
        </>}>
          {pairAwards(awardsData).map((awardsPair, pairIndex) => (
            <div key={pairIndex} className={styles.listAwards}>
              {awardsPair.map(({ number, text, date }, index) => (
                <div key={index} className={styles.item}>
                  <div className={styles.number}>{number}</div>
                  <div className={styles.content}>
                    <p className={styles.text}>{text}</p>
                    <div className={styles.date}>{date}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </SectionContainer>
      </Container>
    </section>
  );
};

export default AwardDate;
