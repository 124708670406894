import React, { useState } from "react";
import styles from "./styles.module.styl";
import MarqueeText from "../../components/marqueeText";

export const WinnerItem = ({ subtitleFn = () => ({}), items = [], marqueeTextFn, itemsPerPage = 4, isMarqueeText = false, classNameMarqueeText, hrefMore }) => {
    const [showAllItems, setShowAllItems] = useState(false);
    const toggleItems = () => {
        setShowAllItems((prevShowAllItems) => !prevShowAllItems);
    };
    const text = marqueeTextFn ? marqueeTextFn(Math.abs(items.length - itemsPerPage)) : `Ещё ${Math.abs(items.length - itemsPerPage)} победителей`;

    return <div className={`${styles.winnerItem}${showAllItems ? ` ${styles.showHideItems}` : ''}`}>
        <p className={styles.subtitle}>{subtitleFn()}</p>
        <div className={styles.winnerContainer}>
            {items.map((props, idx) => {
                return <Item key={idx} idx={idx} {...props} itemsPerPage={itemsPerPage} />
            })}
            {(items.length > itemsPerPage && !showAllItems || isMarqueeText) && (
                <MarqueeText className={`${styles.scrolledText} ${classNameMarqueeText}`} onClick={hrefMore ? undefined : toggleItems} text={text} hrefMore={hrefMore} />
            )}
        </div>
    </div>
};

const Item = ({ url, text, title, itemsPerPage, idx, subtitle }) => {
    const [showText, setShowText] = useState(false);
    const toggleText = () => {
        setShowText((prevShowText) => !prevShowText);
    };
    return <div className={`${idx >= itemsPerPage ? styles.hideItems : ''}${` ${styles.item}`}${showText ? ` ${styles.openText}` : ''}`} onClick={toggleText}>
        <div className={styles.itemSubtitle}>{subtitle}</div>
        <div className={styles.titleContainer}>
            <div className={styles.itemMainInfoWrapper}>
                {url && <img className={styles.img} width={title ? 48 : undefined} height={title ? 48 : undefined} src={url} alt={title} />}
                <div className={styles.title}>{title}</div>
            </div>
            <div className={styles.cross} />
        </div>
        <div className={`${styles.text}${showText ? ` ${styles.showText}` : ''}`}>{text}</div>
    </div>
}
