import React from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";
import SectionContainer from "../../components/sectionContainer";
import { WinnerItem } from "./WinnerItem";

const configWinnerItems = [
  {
    subtitleFn: () => "Лучшие финансовые и страховые продукты 2023 года",
    items: [
      {
        title: "Банк ДОМ.РФ. «Мой Дом»",
        subtitle: 'Вклад года',
        url: '/2023/images/winners/домрфбанк.svg',
        text:
          "При выборе победителя оцениваются вклады сроком на три месяца. Учитывается процентная ставка по вкладу на протяжении года, минимальная сумма депозита, выплата процентов, возможность онлайн-оформления и пополнения. Кроме того, экспертный совет обращает внимание на популярность продукта на Банки.ру, его доступность и удобство оформления — отзывы посетителей Банки.ру как о продукте, так и о качестве обслуживания при его оформлении.",
      },
      {
        title: "А ДЕНЬГИ. «Займ Онлайн»",
        subtitle: 'Микрозайм года',
        url: '/2023/images/winners/аденьги.svg',
        text:
          "Победитель определяется среди предложений микрозаймов в сегменте PDL (до 30 000 рублей на срок до 30 дней). При выборе победителя оценивается популярность продукта на Банки.ру, наличие возможности и условия получения займа под 0%, срок займа, наличие комиссий, дополнительных льготных условий при досрочном погашении, наличие программы лояльности, а также различные способы погашения займа. Также экспертный совет обращает внимание на технологичность и клиентский сервис МФО — отзывы пользователей при оформлении продукта и дальнейшем его обслуживании.",
      },
      {
        title: "ВТБ. «Наличными»",
        subtitle: 'Потребительский кредит года',
        url: '/2023/images/winners/втббанк.svg',
        text:
          "При выборе победителя оценивается прозрачность и доступность условий кредита, динамика процентной ставки в течение года, зависимость ставки от личного страхования, наличие дополнительных условий по снижению или увеличению ставки, требования к пакету документов, скорость и удобство оформления продукта, доступность продукта различным категориям граждан. Кроме того, экспертный совет обращает внимание на популярность продукта на Банки.ру, его доступность и удобство оформления — отзывы посетителей Банки.ру как о продукте, так и о качестве обслуживания при его оформлении и в дальнейшем.",
      },
      {
        title: "ВТБ. «Вторичное жилье»",
        subtitle: 'Ипотечный кредит года',
        url: '/2023/images/winners/втббанк.svg',
        text:
          "При выборе победителя оцениваются ипотечные программы на вторичном рынке недвижимости. Учитываются размеры процентных ставок, динамика их изменения в течение года, минимальный первоначальный взнос, требования к страхованию, наличие дополнительных опций (как снижающих, так и увеличивающих ставку), уровень цифровизации и удобство процесса оформления ипотеки для заемщика. Кроме того, экспертный совет обращает внимание на популярность продукта на Банки.ру, его доступность и удобство оформления — отзывы посетителей Банки.ру как о продукте, так и о качестве обслуживания банка при его оформлении и в дальнейшем.",
      },
      {
        title: "Тинькофф Банк. «Платинум» ",
        subtitle: 'Кредитная карта года',
        url: '/2023/images/winners/тинькофф.svg',
        text:
          "При выборе победителя оценивается продолжительность и условия льготного периода кредитования, возможность бесплатного выпуска и обслуживания карты, наличие и содержание бонусной программы, прозрачность ее условий, удобство онлайн-оформления, наличие доставки, а также доступность различных форм оплаты (Mir Pay, QR-код, платежный стикер и т. п.). Кроме того, экспертный совет обращает внимание на популярность продукта на Банки.ру и клиентский сервис — отзывы посетителей Банки.ру как о продукте, так и о качестве обслуживания при его оформлении и в дальнейшем.",
      },
      {
        title: "Альфа-Банк. «Альфа-Карта»",
        subtitle: 'Дебетовая карта года',
        url: '/2023/images/winners/альфабанк.svg',
        text:
          "При выборе победителя оцениваются условия обслуживания, комиссии и лимиты на снятие наличных и переводы, содержание бонусной программы и прозрачность ее условий, возможность получения процентного дохода по карте, удобство онлайн-оформления, наличие доставки, а также доступность различных форм оплаты (Mir Pay, QR-код, платежный стикер и т. п.). Кроме того, экспертный совет обращает внимание на популярность продукта на Банки.ру и клиентский сервис — отзывы посетителей Банки.ру как о продукте, так и о качестве обслуживания при его оформлении и в дальнейшем.",
      },
      {
        title: "«Зетта Страхование»",
        subtitle: 'Страховой продукт года. Страхование ипотеки',
        url: '/2023/images/winners/зетта.svg',
        text:
          "При выборе победителя оценивается популярность продукта на Банки.ру, мнение клиентов о качестве продукта и сервиса, уровень развития онлайн-сервисов, а также надежность технической реализации продукта. ",
      },
      {
        title: "«Тинькофф Страхование»",
        subtitle: 'Страховой продукт года. КАСКО',
        url: '/2023/images/winners/тинькофф.svg',
        text:
          "При выборе победителя оценивается возможность гибкого управления параметрами страхового покрытия, уникальность и инновационность продукта, наличие качественной сервисной составляющей, подтвержденной пользователями, уровень онлайн-сервисов.",
      },
      {
        title: "«АльфаСтрахование»",
        subtitle: 'Страховой продукт года. ОСАГО',
        url: '/2023/images/winners/альфастрахование.svg',
        text:
          "При выборе победителя оценивается популярность продукта на Банки.ру, мнение пользователей о качестве продукта и сервиса, качество урегулирования страховых случаев, а также адаптивность и надежность технической реализации продукта.",
      },
      {
        title: "КАМКОМБАНК",
        subtitle: 'Курс валют. Лучшие условия обмена',
        url: '/2023/images/winners/камкомбанк.svg',
        text:
          "При выборе победителя оценивается курс покупки и продажи валюты на протяжении года, количество валютных пар и обменных пунктов/офисов, комиссии при обмене, возможность бронирования курса и суммы. Кроме того, принимаются во внимание отзывы клиентов.",
      },
    ],
  },
  {
    subtitleFn: () => "Народный рейтинг 2023 года",
    items: [
      {
        title: "Альфа-Банк",
        subtitle: 'Победитель Народного рейтинга банков',
        url: '/2023/images/winners/альфабанк.svg',
        text:
          "Победителем становится лидер Народного рейтинга банков по итогам 2023 года.",
      },
      {
        title: "«Совкомбанк страхование» ",
        subtitle: 'Победитель Народного рейтинга страховых компаний',
        url: '/2023/images/winners/совкомбанк.svg',
        text:
          "Победителем становится лидер Народного рейтинга страховых компаний по итогам 2023 года.",
      },
      {
        title: "РСХБ – Брокер",
        subtitle: 'Победитель Народного рейтинга инвестиционных компаний',
        url: '/2023/images/winners/РСХБ.svg',
        text:
          "Победителем становится лидер Народного рейтинга инвестиционных компаний по итогам 2023 года.",
      },
      {
        title: "«еКапуста»",
        subtitle: 'Победитель Народного рейтинга микрофинансовых компаний',
        url: '/2023/images/winners/екапуста.svg',
        text:
          "Победителем становится лидер Народного рейтинга микрофинансовых компаний по итогам 2023 года.",
      },
    ],
  },
  {
    subtitleFn: () => "Финтехрешение года",
    items: [
      {
        title: "Росбанк",
        url: '/2023/images/winners/росбанк.svg',
        text:
          "При выборе победителя оценивается технологичность банка, привлекательные условия продукта для клиента с возможностью end-to-end процесса и количество доступных на финансовой платформе Банки.ру (ОФП, АО «Банки.ру Маркетплейс») продуктов банка.",
      },
    ],
  },
  {
    subtitleFn: () => "Лучшие компании 2023 года",
    items: [
      {
        title: "«Займер»",
        subtitle: 'МФК года',
        url: '/2023/images/winners/займер.svg',
        text:
          "При выборе победителя оценивается динамика показателей основного бизнеса компании — рост портфеля микрозаймов, объем выдач, финансовая надежность и технологичность МФК, а также отзывы клиентов о компании в Народном рейтинге Банки.ру.",
      },
      {
        subtitle: 'Инвестиционная компания года',
        url: '/2023/images/winners/сберинвестиции.svg',
        text:
          "При выборе победителя оцениваются ключевые финансовые показатели компании, рост ее бизнеса и занимаемая доля рынка, основные достижения компании в 2023 году, а также мнение пользователей Народного рейтинга инвестиционных компаний Банки.ру.",
      },
      {
        title: "ВСК",
        subtitle: 'Страховая компания года',
        url: '/2023/images/winners/вск.svg',
        text:
          "При выборе победителя оценивается надежность страховой компании, популярность среди пользователей Банки.ру и их мнение о компании, уровень инновационности и процессы компании при выплате страхового возмещения.",
      },
      {
        title: "Альфа-Банк",
        subtitle: 'Банк года',
        url: '/2023/images/winners/альфабанк.svg',
        text:
          "При выборе победителя оцениваются ключевые финансовые показатели: динамика активов, клиентских средств, кредитного портфеля, чистой прибыли и другие. Важную роль в оценке также играют достижения компании в 2023 году — результаты запущенных проектов, позиции в отраслевых рейтингах, оценка надежности от кредитных рейтинговых агентств, рост бизнеса за счет сделок M&A или его масштабирование в смежных секторах, уровень клиентоориентированности в условиях экономической турбулентности (в том числе с использованием показателей Народного рейтинга Банки.ру).",
      },
    ],
  },
  {
    subtitleFn: () => "Комьюнити Банки.ру",
    items: [
      {
        title: "Сat in the rain",
        subtitle: 'Человек года на форуме Банки.ру',
        text:
          "Победитель определяется среди кандидатов с наивысшим коэффициентом полезной активности по итогам открытого онлайн-голосования на форуме Банки.ру",
      },
    ],
  },
];

const WinnerBlock = () => {
  return (
    <section className={styles.winner}>
      <Container className={styles.container}>
        <SectionContainer
          headlineFn={() => "Победители"}
          style={{ headline: styles.sectionHeadline }}
        >
          {configWinnerItems.map((item, idx) => {
            return <WinnerItem key={idx} {...item} />;
          })}
        </SectionContainer>
      </Container>
    </section>
  );
};

export default WinnerBlock;
