import React from "react";
import styles from "./styles.module.styl";

const AnimatedCircle = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ margin: 'auto', background: 'transparent', display: 'block' }}
      width="64px"
      height="64px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#71c4cc"
        strokeWidth="8"
        r="32"
        strokeDasharray="150.79644737231007 52.26548245743669"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.9615384615384615s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  );

const Preloader = () => {
    return (
        <div className={styles.preloader}>
            <AnimatedCircle />
        </div>
    );
};

export default Preloader;
