import React from "react";
import styles from "./styles.module.styl";

const SectionContainer = ({ children, headlineFn = () => ({}), style = {} }) => {
  const { headline, containerSection, content } = style;
  return (
    <>
      <h2 className={`${styles.sectionHeadline}${headline ? ` ${headline}` : ''}`}>{headlineFn()}</h2>
      <div className={`${styles.containerSection}${containerSection ? ` ${containerSection}` : ''}`}>
        <div className={`${styles.content}${content ? ` ${content}` : ''}`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default SectionContainer;
