import React from "react";
import styles from "./styles.module.styl";

const SofitBlock = () => {
  return (
    <div className={styles.sofitBlock} key="sofit-block">
      <picture>
        <source
          srcSet="/2023/images/sofit-l.webp"
          type="image/webp"
          media="screen and (min-width: 1920px)"
        />
        <source
          srcSet="/2023/images/sofit-m.webp"
          type="image/webp"
          media="screen and (min-width: 768px)"
        />
        <source
          srcSet="/2023/images/sofit-s.webp"
          type="image/webp"
          media="screen and (max-width: 375px)"
        />
        <source
          srcSet="/2023/images/sofit-l.png"
          type="image/png"
          media="screen and (min-width: 1920px)"
        />
        <source
          srcSet="/2023/images/sofit-m.png"
          type="image/png"
          media="screen and (min-width: 768px)"
        />
        <source
          srcSet="/2023/images/sofit-s.png"
          type="image/png"
          media="screen and (min-width: 320px)"
        />
        <img
          className={styles.image}
          src="/2023/images/sofit-l.png"
          alt="софит блок"
          aria-hidden
        />
      </picture>
    </div>
  );
};

export default SofitBlock;
