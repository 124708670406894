import React from 'react';
import styles from "./styles.module.styl";


const MarqueeText = ({ text, onClick, hrefMore, className }) => {
    const content = <div className={`${styles.scrolledText}${className ? ` ${className}` : ''}`} onClick={hrefMore ? undefined : onClick}>
        <div className={`${styles.wrapper} ${styles.activeAnimation}`}>
            <span className={styles.text}>{text}</span> <span className={styles.text}>{text}</span>
            <span className={styles.text}>{text}</span> <span className={styles.text}>{text}</span>
            <span className={styles.text}>{text}</span> <span className={styles.text}>{text}</span>
            <span className={styles.text}>{text}</span>
        </div>
        <div className={`${styles.wrapper} ${styles.activeAnimation}`}>
            <span className={styles.text}>{text}</span> <span className={styles.text}>{text}</span>
            <span className={styles.text}>{text}</span> <span className={styles.text}>{text}</span>
            <span className={styles.text}>{text}</span> <span className={styles.text}>{text}</span>
            <span className={styles.text}>{text}</span>
        </div>
    </div>

    return <>
        {hrefMore ? <a href={hrefMore} rel="noreferrer" target='_blank'>{content}</a> : content}
    </>
};


export default MarqueeText;