import React from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";
import SectionContainer from "../../components/sectionContainer";

const AwardInfo = () => {
  return (
    <section className={styles.awardInfo}>
      <Container className={styles.container}>
        <SectionContainer headlineFn={() => "О премии"}>
          <p className={styles.descList}>
            За 16 лет мы вручили более 300 наград лучшим банкам, МФО,
            страховым и инвестиционным компаниям:
          </p>
          <ul className={styles.list}>
            <li>за востребованные и выгодные финансовые продукты</li>
            <li>за высокий уровень клиентского сервиса</li>
            <li>за вклад в развитие отрасли</li>
          </ul>
          <p className={styles.title}>Премия, которая помогает стать лучше</p>
          <p className={styles.desc}>
            Пользователи Банки.ру получают достоверную и непредвзятую
            информацию о финансовых продуктах и компаниях, а партнеры —
            ориентиры для дальнейшего развития.
          </p>
          <p className={styles.title}>Премия, которой доверяют</p>
          <p className={styles.desc}>
            В выборе победителей участвует не только экспертный совет премии,
            <br />
            но и пользователи — с помощью онлайн-голосования и Народного
            рейтинга Банки.ру
          </p>
        </SectionContainer>
      </Container>
    </section>
  );
};

export default AwardInfo;
