import React from "react";
import styles from "./styles.module.styl";

const Container = ({ children, fullscreenMobile, className }) => {
  const containerClass = fullscreenMobile
    ? `${styles.container} ${styles.fullscreenMobile}`
    : styles.container;

  return (
    <div className={`${containerClass}${className ? ` ${className}` : ""}`}>
      {children}
    </div>
  );
};

export default Container;
