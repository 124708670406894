import React from "react";
import { Helmet } from "react-helmet";

export const Head = () => {
  return (
    <Helmet>
      <html lang="ru" />
      <title>Премия Банки.ру 2023</title>
      <meta
        name="description"
        content="Премия, которой доверяют: в выборе победителей участвует не только экспертный совет премии, но и пользователи - с помощью Народного рейтинга Банки.ру"
      />
      <link
        rel="preload"
        fetchpriority="high"
        as="image"
        href="/2023/images/main-bg.png"
        type="image/png"
      />
      <style type="text/css">
        {`html {
  -webkit-text-size-adjust: 100%;
  background: #091138;
  background-size: cover;
  color: #fff;
  font-family: Bebas-Neue,sans-serif!important;
  font-size: 10px;
  font-weight: 700;
  margin: 0;
  min-height: 300px;
  box-sizing: border-box;
}

body,html {
  height: 100%;
  padding: 0
}

body {
  -webkit-font-smoothing: antialiased;
  background: #091138;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none
}

@font-face {
  font-family: Bebas-Neue;
  font-style: normal;
  font-weight: 700;
  src: url(/2023/fonts/BebasNeueBold.eot),url(/2023/fonts/BebasNeueBold.eot?#iefix) format("embedded-opentype"),url(/2023/fonts/BebasNeueBold.woff) format("woff"),url(/2023/fonts/BebasNeueBold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Jost-Bold;
  font-style: normal;
  font-weight: 700;
  src: url(/2023/fonts/Jost-Bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Jost-Light;
  font-style: normal;
  font-weight: 300;
  src: url(/2023/fonts/Jost-Light.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Jost-Regular;
  font-style: normal;
  font-weight: 400;
  src: url(/2023/fonts/Jost-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Jost-Medium;
  font-style: normal;
  font-weight: 500;
  src: url(/2023/fonts/Jost-Medium.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 400;
  src: url(/2023/fonts/Roboto-Regular.eot),
  url(/2023/fonts/Roboto-Regular.eot?#iefix) 
  format("embedded-opentype"),url(/2023/fonts/Roboto-Regular.woff) 
  format("woff"),url(/2023/fonts/Roboto-Regular.ttf) 
  format("truetype");
  font-display: swap;
}

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,
dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,
menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,summary,table,tbody,td,tfoot,th,thead,time,tr,tt,u,
ul,var,video {
  border: none;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

main {
  position: relative;
  z-index: 1;
  background-image: none;
  overflow-x: initial;
}

*,:after,:before {
  box-sizing: inherit
}`}
      </style>
    </Helmet>
  );
};
