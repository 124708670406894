import React, { useRef, useState } from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";
import SectionContainer from "../../components/sectionContainer";
import MarqueeText from "../../components/marqueeText";

const videoSrc = '/2023/images/video/bank_year.mp4';
const previewSrc = '/2023/images/video/preview.jpg';

const Partners = () => {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const playVideo = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }

  };

  return (
    <section className={styles.partners}>
      <Container className={styles.container}>
        <SectionContainer headlineFn={() => "Онлайн-трансляция"}
          style={{ containerSection: styles.containerSection, headline: styles.sectionHeadline }}
        >
          <div className={styles.videoBlock}>
            <div className={styles.previewContainer}>
              <button aria-label="play-video-button" className={`${styles.playButton}${playing ? ` ${styles.hidden}` : ''}`} onClick={() => videoRef.current.play()}>
                <span className={styles.playIcon}></span>
              </button>
            </div>
            <video ref={videoRef} controls poster={previewSrc} onPlaying={playVideo} onPause={playVideo} playsInline preload="metadata">
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </SectionContainer>
      </Container>
      <MarqueeText text="Как это было в 2023" className={styles.scrolledText}/>
      <MarqueeText text="Как это было в 2023" className={styles.scrolledText}/>
      <MarqueeText text="Как это было в 2023" className={styles.scrolledText}/>
    </section>
  );
};

export default Partners;
