export const configShortListItems = [
  {
    title: "Вклад года",
    logos: [
      {
        url: "/2023/images/shortList/альфа-банк.svg",
        title: "«Альфа-вклад. Максимальный»",
      },
      { url: "/2023/images/shortList/дом-рф.svg", title: "«Мой Дом»" },
      { url: "/2023/images/shortList/банк-открытие.svg", title: "«Надежный»" },
      { url: "/2023/images/shortList/россельхозбанк.svg", title: "«Доходный»" },
      {
        url: "/2023/images/shortList/тинькофф-банк.svg",
        title: "«СмартВклад (с повышенной ставкой)»",
      },
    ],
  },
  {
    title: "Микрозайм года",
    logos: [
      { url: "/2023/images/shortList/Деньги.png", title: "«Займ онлайн»" },
      { url: "/2023/images/shortList/мигкредит.svg", title: "«Займ онлайн»" },
      { url: "/2023/images/shortList/СМС_финанс.svg", title: "«Займ 0%»" },
      { url: "/2023/images/shortList/moneyman.svg", title: "«Старт 0%»" },
      { url: "/2023/images/shortList/PayP.S.svg", title: "«Займ онлайн» " },
    ],
  },
  {
    title: "Потребительский кредит года",
    logos: [
      { url: "/2023/images/shortList/АТБ-банк.svg", title: "«Наличными»" },
      { url: "/2023/images/shortList/ВТБ.svg", title: "«Наличными»" },
      { url: "/2023/images/shortList/Газпром.svg", title: "«Наличными»" },
      { url: "/2023/images/shortList/тинькофф-банк.svg", title: "«Наличными»" },
      { url: "/2023/images/shortList/хоум-банк.svg", title: "«Наличными»" },
    ],
  },
  {
    title: "Ипотечный кредит года",
    logos: [
      {
        url: "/2023/images/shortList/альфа-банк.svg",
        title: "«Готовое жилье»",
      },
      { url: "/2023/images/shortList/дом-рф.svg", title: "«Готовое жилье»" },

      { url: "/2023/images/shortList/ВТБ.svg", title: "«Вторичное жилье»" },
      { url: "/2023/images/shortList/росбанк.svg", title: "«Квартира»" },
      {
        url: "/2023/images/shortList/сбербанк.svg",
        title: "Приобретение готового жилья",
      },
    ],
  },
  {
    title: "Кредитная карта года",
    logos: [
      {
        url: "/2023/images/shortList/альфа-банк.svg",
        title: "«Целый год без процентов»",
      },
      { url: "/2023/images/shortList/ВТБ.svg", title: "«Карта возможностей»" },
      { url: "/2023/images/shortList/мтс-банк.svg", title: "«МТS Cashback»" },
      { url: "/2023/images/shortList/тинькофф-банк.svg", title: "«Платинум»" },
      {
        url: "/2023/images/shortList/уралсиб.svg",
        title: "«120 дней на максимум»",
      },
    ],
  },
  {
    title: "Дебетовая карта года",
    logos: [
      { url: "/2023/images/shortList/альфа-банк.svg", title: "«Альфа-Карта»" },
      {
        url: "/2023/images/shortList/ВТБ.svg",
        title: '«Карта для жизни»',
      },
      {
        url: "/2023/images/shortList/Газпром.svg",
        title: '«Умная карта "Мир"»',
      },
      { url: "/2023/images/shortList/сбербанк.svg", title: "«СберКарта»" },
      {
        url: "/2023/images/shortList/тинькофф-банк.svg",
        title: "Tinkoff Black",
      },
    ],
  },
  {
    title: "Страховой продукт года. Страхование ипотеки",
    logos: [
      { url: "/2023/images/shortList/абсолют.svg" },
      { url: "/2023/images/shortList/вск.svg" },
      { url: "/2023/images/shortList/зетта.svg" },
      { url: "/2023/images/shortList/ренессанс.svg" },
      { url: "/2023/images/shortList/югория.svg" },
    ],
  },
  {
    title: "Страховой продукт года. КАСКО",
    logos: [
      { url: "/2023/images/shortList/альфастрахование.svg" },
      { url: "/2023/images/shortList/вск.svg" },
      { url: "/2023/images/shortList/ренессанс.svg" },
      { url: "/2023/images/shortList/росгосстрах.svg" },
      {
        url: "/2023/images/shortList/тинькофф-банк.svg",
      },
    ],
  },
  {
    title: "Страховой продукт года. ОСАГО",
    logos: [
      { url: "/2023/images/shortList/альфастрахование.svg" },
      { url: "/2023/images/shortList/вск.svg" },
      { url: "/2023/images/shortList/ренессанс.svg" },
      { url: "/2023/images/shortList/росгосстрах.svg" },
      { url: "/2023/images/shortList/сберстрахование.svg" },
    ],
  },
  {
    title: "Курс валют. Лучшие условия обмена",
    logos: [
      { url: "/2023/images/shortList/Агророс.svg" },
      { url: "/2023/images/shortList/банк-казани.svg" },
      { url: "/2023/images/shortList/камкомбанк.svg" },
      { url: "/2023/images/shortList/сбербанк.svg" },
      { url: "/2023/images/shortList/совкомбанк.svg" },
    ],
  },
  {
    title: "Финтехрешение года",
    logos: [
      { url: "/2023/images/shortList/дом-рф.svg" },
      { url: "/2023/images/shortList/кредит-урал-банк.svg" },
      { url: "/2023/images/shortList/ресо-кредит.svg" },
      { url: "/2023/images/shortList/росбанк.svg" },
      { url: "/2023/images/shortList/синара-банк.svg" },
    ],
  },
  {
    title: "МФК года",
    logos: [
      { url: "/2023/images/shortList/Вебзайм.svg" },
      { url: "/2023/images/shortList/Займер.svg" },
      { url: "/2023/images/shortList/мигкредит.svg" },
      { url: "/2023/images/shortList/moneyman.svg" },
      { url: "/2023/images/shortList/oneclickmoney.svg" },
    ],
  },
  {
    title: "Инвестиционная компания года",
    logos: [
      {
        url: "/2023/images/shortList/альфа-банк.svg",
      },
      {
        url: "/2023/images/shortList/БКС.svg",
      },
      {
        url: "/2023/images/shortList/ВТБ.svg",
      },
      {
        url: "/2023/images/shortList/сбер-инвестиции.svg",
      },
      {
        url: "/2023/images/shortList/тинькофф-банк.svg",
      },
    ],
  },
  {
    title: "Страховая компания года",
    logos: [
      { url: "/2023/images/shortList/альфастрахование.svg" },
      { url: "/2023/images/shortList/вск.svg" },
      { url: "/2023/images/shortList/ингос.svg" },
      { url: "/2023/images/shortList/сберстрахование.svg" },
      {
        url: "/2023/images/shortList/тинькофф-банк.svg",
      },
    ],
  },
  {
    title: "Банк года",
    logos: [
      {
        url: "/2023/images/shortList/альфа-банк.svg",
      },
      { url: "/2023/images/shortList/дом-рф.svg" },
      { url: "/2023/images/shortList/почтабанк.svg" },
      { url: "/2023/images/shortList/росбанк.svg" },
      {
        url: "/2023/images/shortList/тинькофф-банк.svg",
      },
    ],
  },
];
