import React from "react";
import Container from "../container";
import styles from "./styles.module.styl";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.content}>
          <p className={styles.text}>
            © 2018—2023 ООО
            <br className={`${styles.hideDesktop}`} />
            «Банки.ру Искусственный интеллект».
            <br className={`${styles.hideOnlyMobile}`} />
            <br className={`${styles.hideDesktop}`} />
            ОГРН: 1077763469882, адрес: 117638, г. Москва,
            <br className={`${styles.hideDesktop}`} />
            улица Одесская, д. 2, этаж 19
          </p>
          <div className={styles.divider}></div>
          <div className={styles.icons}>
            <a
              target="_blank"
              rel="noreferrer"
              href="//zen.yandex.ru/banki.ru"
              className={styles.icon}
            >
              <img alt='gzen' src="/2023/images/icons/dzen.svg" width={24} height={24} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="//twitter.com/banki_ru"
              className={styles.icon}
            > <img alt='twitter' src="/2023/images/icons/twitter.svg" width={24} height={24} /> </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="//vk.com/bankiru_club"
              className={styles.icon}
            > <img alt='vk' src="/2023/images/icons/vk.svg" width={24} height={24} /> </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/bankiruofficial"
              className={styles.icon}
            > <img alt='tg' src="/2023/images/icons/telegram.svg" width={24} height={24} /> </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
