import React, { useEffect } from "react";
import { initAnalyticsDOMEvents } from "@bankiru/core-scripts/analytics/init-dom-events";
import { Head } from "./Head";
import Header from "./components/header";
import MainBlock from "./sections/main-block";
import AwardDate from "./sections/award-date";
import AwardInfo from "./sections/award-info";
import News from "./sections/news";
import VideoBlock from "./sections/video-block";
import Partners from "./sections/partners";
import AwardHistory from "./sections/award-history";
import Footer from "./components/footer";
import SofitBlock from "./components/sofitBlock";
import MainBlockBg from "./sections/main-block-bg";
import ShortList from "./sections/shortList";
import WinnerBlock from "./sections/winners-block";

const BankOfYear2023 = ({ pageContext }) => {
  const counters = pageContext?.counters || {};

  useEffect(() => {
    initAnalyticsDOMEvents();
  }, []);

  const countersHead = counters?.["head"];
  const countersBodyTop = counters?.["body-top"];
  const countersBodyBottom = counters?.["body-bottom"];

  return (
    <div>
      {countersHead && (
        <div dangerouslySetInnerHTML={{ __html: countersHead }} />
      )}
      {countersBodyTop && (
        <div dangerouslySetInnerHTML={{ __html: countersBodyTop }} />
      )}
      <div style={{ overflow: "hidden" }}>
        <Head />
        <SofitBlock />
        <MainBlockBg />
        <Header />
        <main>
          <MainBlock />
          <AwardInfo />
          <AwardDate />
          <WinnerBlock />
          <ShortList />
          <News />
          <Partners />
          <VideoBlock />
          <AwardHistory />
        </main>
        <Footer />
      </div>

      {countersBodyBottom && (
        <div dangerouslySetInnerHTML={{ __html: countersBodyBottom }} />
      )}
    </div>
  );
};

export default BankOfYear2023;
