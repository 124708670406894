import React from "react";
import styles from "./styles.module.styl";
import Container from "../container";

const Header = () => {
  return (
    <header className={styles.header}>
      <Container>
        <a target="_blank" rel="noreferrer" href="https://www.banki.ru/">
          <div className={styles.logo}>
            <img
              src="/2023/images/logo.svg"
              alt="Логотип"
              width="179px"
              height="28px"
            />
          </div>
        </a>
      </Container>
    </header>
  );
};

export default Header;
