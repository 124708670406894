import React from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";
import SectionContainer from '../../components/sectionContainer'
import { getBankOfYearUrl } from "../../../constants";

const years = [];
const CURRENT_YEAR = 2023;

for (let i = CURRENT_YEAR; i >= 2009; i--) {
  years.push(i);
}

const AwardHistory = () => {
  return (
    <section className={styles.awardHistory}>
      <Container className={styles.container}>
        <SectionContainer headlineFn={() => <>История <br className={styles.hideMobile} />премии</>}
          style={{ containerSection: styles.containerSection, headline: styles.sectionHeadline }}>
          <div className={styles.history}>
            {years.map((year, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={`${styles.historyItem} ${year === CURRENT_YEAR ? styles.currentHistoryItem : ''}`}>
                    <a href={getBankOfYearUrl(year)} className={styles.circle} aria-label={`link ${year}`}></a>
                    <div className={styles.year}>{year}</div>
                  </div>
                  {index !== years.length - 1 && <div className={styles.line} />}
                </React.Fragment>
              );
            })}

          </div>
        </SectionContainer>
      </Container>

    </section>
  );
};

export default AwardHistory;
