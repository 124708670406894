import React from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";

const MainBlock = () => {
  return (
    <section className={styles.mainBlock}>
      <Container fullscreenMobile>
        <div className={styles.content}>
          <div className={styles.title}>ИТОГИ 2023</div>
          <div className={styles.mainBlockHeadline}>
            ПРЕМИЯ
            <br />
            БАНКИ.РУ
          </div>
          <div className={styles.description}>XVII церемония вручения</div>
          <div className={styles.mouseWrapper}>
            <div className={styles.mouse}>
              <div className={styles.mouseScroll}></div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MainBlock;
