import { useState, useEffect } from "react";

export const DESKTOP = "desktop";
export const TABLET = "tablet";

function useDeviceType() {
  const [deviceType, setDeviceType] = useState(DESKTOP);

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType());
    };

    if (window) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  return deviceType;
}

function getDeviceType() {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    return TABLET;
  }

  return DESKTOP;
}

export default useDeviceType;
