import React, { useEffect, useMemo, useState } from "react";
import { BANKIRU_URL } from "../../../constants";
import Container from "../../components/container";
import styles from "./styles.module.styl";
import Preloader from "./preloader";
import MarqueeText from "../../components/marqueeText";
import SectionContainer from "../../components/sectionContainer";

const refactorDate = (fullDate = "") => {
  const date = new Date(fullDate);

  return new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(date);
};

const newsPerPage = 4;
const SERVICE_NEWS_URL_PROD = "https://www.banki.ru/wikibank/api/awards/";

const News = () => {
  const [loading, setLoading] = useState(true);
  const [showAllNews, setShowAllNews] = useState(false);
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(SERVICE_NEWS_URL_PROD, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (!response.ok || response.status !== 200) {
          console.error("Ошибка при загрузке новостей");
          return;
        }
        const news = await response.json();
        setNewsList(news.data?.collection || []);
      } catch (error) {
        console.error("Ошибка при загрузке новостей");
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const toggleNews = () => {
    setShowAllNews((prevShowAllNews) => !prevShowAllNews);
  };

  const renderNews = useMemo(() => {
    return newsList.map((news, idx) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`${BANKIRU_URL}/news/${news.category}/?id=${news.id}`}
        key={news.id}
        className={idx >= newsPerPage ? styles.hideNew : ""}
      >
        <img
          src={news.previewImage3}
          alt={news.title}
          className={styles.img}
          loading="lazy"
          width={416}
          height={225}
        />
        <div className={styles.title}>{news.title}</div>
        <div className={styles.date}>{refactorDate(news.dateCreate)}</div>
      </a>
    ));
  }, [newsList]);

  if(!newsList.length){
    return null;
  }

  return (
    <section className={styles.news}>
      <Container className={styles.container}>
        <SectionContainer
          headlineFn={() => (
            <>
              Новости
              <br className={styles.hideMobile} /> о премии{" "}
              <br className={styles.showMobile} /> Банки.ру
            </>
          )}
          style={{
            containerSection: styles.containerSection,
            headline: styles.sectionHeadline,
            content: styles.content,
          }}
        >
          {(loading || newsList.length === 0) && <Preloader />}
          {!loading && newsList.length > 0 && (
            <div
              className={`${styles.contentNews}${
                showAllNews ? ` ${styles.showHideNew}` : ""
              }`}
            >
              {renderNews}
            </div>
          )}
          {!loading && newsList.length > 0 && (
            <MarqueeText
              onClick={toggleNews}
              text={"Ещё новости"}
              hrefMore={
                showAllNews || newsList.length <= newsPerPage
                  ? "https://www.banki.ru/news/lenta/projects/"
                  : undefined
              }
            />
          )}
        </SectionContainer>
      </Container>
    </section>
  );
};

export default News;
