import React, { useState } from "react";
import styles from "./styles.module.styl";
import Container from "../../components/container";
import SectionContainer from "../../components/sectionContainer";
import MarqueeText from "../../components/marqueeText";
import { configShortListItems } from "./configShortListItems";

const itemsPerPage = 4;
const ShortList = () => {
  const [showAllItems, setShowAllItems] = useState(false);
  const toggleItems = () => {
    setShowAllItems((prevShowAllItems) => !prevShowAllItems);
  };

  return (
    <section className={styles.shortList}>
      <Container className={styles.container}>
        <SectionContainer
          headlineFn={() => "Шорт-лист"}
          style={{
            headline: styles.sectionHeadline,
            containerSection: styles.containerSection,
          }}
        >
          <div className={`${showAllItems ? ` ${styles.showHideItems}` : ""}`}>
            {configShortListItems.map(({ title, logos }, idx) => {
              return (
                <div
                  className={`${
                    idx >= itemsPerPage ? styles.hideItems : ""
                  }${` ${styles.item}`}`}
                  key={idx}
                >
                  <p className={styles.title}>{title}</p>
                  <div className={styles.containerImg}>
                    {logos.map(({ url, title }, idx) => {
                      return (
                        <div key={idx}>
                          {url && (
                            <img
                              src={url}
                              className={styles.img}
                              loading="lazy"
                              width="120"
                              height="48"
                            />
                          )}
                          {title && (
                            <div className={styles.imgTitle}>{title}</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {configShortListItems.length > itemsPerPage && !showAllItems && (
              <MarqueeText
                className={`${styles.scrolledText}`}
                onClick={toggleItems}
                text={`Ещё ${Math.abs(
                  configShortListItems.length - itemsPerPage
                )} номинантов`}
              />
            )}
          </div>
        </SectionContainer>
      </Container>
    </section>
  );
};

export default ShortList;
